import NavigationLarge from './navigation-large';
import JshModule from '@core/scripts/helper/jsh-module';

const {moduleScope, config} = JshModule('header/navigation');

if (!config?.testGroup) {
    import('./navigation-small.js').then(NavigationSmall => {
        NavigationSmall.default(moduleScope, config);
    });
}

NavigationLarge(moduleScope);
